// User Service
import { isAllClustersScope, isMcm } from '@cohesity/iris-core';
import { BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';

;(function(angular) {
  'use strict';

  // 30 minutes
  const defaultInactivityMsecs = 1000 * 60 * 30;

  var allowedInactivityMsecs = defaultInactivityMsecs;

  // This determines how long the user will see the logout message which gives
  // them the opportunity to prevent logout.
  var logoutDelayMs = 60000;

  // Key for impersonation data overriding loginData.
  var impersonationDataKey = 'loginDataImpersonationOverride';

  var _cleanupTransition = angular.noop;
  var activityTimeoutPromise;

  // Used to store the user privs of the local logged in user.
  var localUserPrivs;

  angular
    .module('C')
    .service('UserService', UserServiceFn);

  function UserServiceFn(_, $rootScope, $http, $q, $state, $timeout, $log,
    $transitions, $filter, SourceService, localStorageService, API, cUtils,
    cModal, clusterState, evalAJAX, cMessage, GroupService, SlideModalService,
    FEATURE_FLAGS, HOME_STATE, TenantService, NgTenantService, DateTimeService,
    ClusterService, BUILT_IN_USERS, $window, $location, NgBroadcastChannelService,
    NgUserStoreService, NgWorkflowAccessContextService, $injector, NG_IS_IBM_AQUA_ENV) {

    _.assign($rootScope, {
      getUserTenantId: NgUserStoreService.getUserTenantId,
      isEntityOwner: isEntityOwner,
      isRestrictedUser: NgUserStoreService.isRestrictedUser,
      isTenantsAncestor: isTenantsAncestor,
      isTenantUser: isTenantUser,
      isHeliosTenantUser: isHeliosTenantUser,
      isBifrostTenantUser: isBifrostTenantUser,
    });

    var userService = {
      user: {},

      authenticate: authenticate,
      authenticateIDP: authenticateIDP,
      capturePreLoginRequest: capturePreLoginRequest,
      createHeliosSession: createHeliosSession,
      createRole: createRole,
      createSession: createSession,
      createUser: createUser,
      deletePrincipals: deletePrincipals,
      deleteRoles: deleteRoles,
      deleteUsers: deleteUsers,
      destroySession: destroySession,
      generateNewS3SecretKey: generateNewS3SecretKey,
      getAllMcmPrivileges: getAllMcmPrivileges,
      getAllPrincipals: getAllPrincipals,
      getAllPrivileges: getAllPrivileges,
      getAllRoles: getAllRoles,
      getAllUsers: getAllUsers,
      getDefaultBifrostConnectionId: getDefaultBifrostConnectionId,
      getLastLoginTime: getLastLoginTime,
      getLoginData: getLoginData,
      getMcmUser: getMcmUser,
      getMcmUserPrivs: getMcmUserPrivs,
      getMfaConfig: getMfaConfig,
      getOrgMemberships: getOrgMemberships,
      getPreLoginRequestUrl: getPreLoginRequestUrl,
      getProtectionSources: getProtectionSources,
      getRole: getRole,
      getSessionUser: getSessionUser,
      getSessionUserPrivs: getSessionUserPrivs,
      getTenantGroups: getTenantGroups,
      getTenantUsers: getTenantUsers,
      getUser: getUser,
      informAndLogOutUser: informAndLogOutUser,
      isBifrostTenantUser: isBifrostTenantUser,
      isCertificateOnlyAuthentication: isCertificateOnlyAuthentication,
      isEntityOwner: isEntityOwner,
      isHeliosTenantUser: isHeliosTenantUser,
      isIbmBaasEnabled: isIbmBaasEnabled,
      isLoginBannerEnabled: isLoginBannerEnabled,
      isIdpAutoLoginEnabled: isIdpAutoLoginEnabled,
      isIdpLogoutPageEnabled: isIdpLogoutPageEnabled,
      isIdpUser: isIdpUser,
      isLoggedIn: isLoggedIn,
      isNotTenantAuthorized: isNotTenantAuthorized,
      isSameAsRedirectUser: isSameAsRedirectUser,
      isTenantsAncestor: isTenantsAncestor,
      isTenantUser: isTenantUser,
      loginSuccess: loginSuccess,
      logout: logout,
      modifyUserModal: modifyUserModal,
      redirectPostLogin: redirectPostLogin,
      registerNewActivity: registerNewActivity,
      removeUnclaimedClusters: removeUnclaimedClusters,
      setInactivityTimeout: setInactivityTimeout,
      updateClusterForUser: updateClusterForUser,
      updateImpersonationUser: updateImpersonationUser,
      updateLocalStorage: updateLocalStorage,
      updateUserLoginData: updateUserLoginData,
      updateProtectionSources: updateProtectionSources,
      updateRole: updateRole,
      updateSessionUser: updateSessionUser,
      updateSessionUserPrivs: updateSessionUserPrivs,
      updateUser: updateUser,
      updateUserOnRootScope: updateUserOnRootScope,
      userSettingsModal: userSettingsModal,
      validateSessionUser: validateSessionUser,
      viewUserDetailsModal: viewUserDetailsModal,

      // API Key methods
      getApiKey: getApiKey,
      getApiKeys: getApiKeys,
      createApiKey: createApiKey,
      updateApiKey: updateApiKey,
      rotateApiKey: rotateApiKey,
      deleteApiKey: deleteApiKey,
    };

    /**
     * Used to track stateName and params of a state request before user is logged in.
     */
    var preLoginRequest = {};

    /**
     * In the event that preLoginRequest is set during the logout process,
     * this variable is used to cache the user for which this request was made.
     */
    var preLoginRequestUserSid;

    /**
     * The currently or most recently logged in user's sid. This is used to
     * compare against preLoginRequestUserSid so as not to redirect to
     * preLoginRequest state and params if a different user is used to login
     * to the system.
     */
    var userSid;

    var usersEndpoint = API.public('users');
    var rolesEndpoint = API.public('roles');
    var protectionSourcesEndpoint = API.public('principals/protectionSources');

    var asterisks = "*******************************************";

    NgBroadcastChannelService.subscribe('autoAppCloseLogout', () => {
      // Clear local storage when app is auto logged out.
      destroySession();
    });

    ////////////////////////////////////////
    // PUBLIC METHODS
    ////////////////////////////////////////

    /**
     * Returns current login data.
     *
     * @method  getLoginData
     * @return  {object}  Login data object.
     */
    function getLoginData() {
      return NgUserStoreService.loginData;
    }

    /**
     * Determines whether the logged-in user organization is owning the provided
     * entity like vlan, user, viewboxes, remote cluster, source etc
     *
     * @param    {String | Array}     entityOwnerTenantIds
     * @return   {Boolean}            Return true if logged-in user organization
     *                                is the owner of the provided entity else
     *                                false.
     */
    function isEntityOwner(entityOwnerTenantIds) {
      // perform match with logged in user tenantId or impersonating
      // organization tenantId.
      var userTenantId = NgUserStoreService.getUserTenantId();

      if (_.get(ClusterService, 'basicClusterInfo.mcmMode')) {

        if (_.get($rootScope, 'clusterInfo._serviceType') === 'dms') {
          var dmsTenantId = _.get($rootScope, 'user.profiles[0].tenantId');
          userTenantId = dmsTenantId ? dmsTenantId.split(':')[1] : undefined;
        }
      }

      if (!_.isArray(entityOwnerTenantIds)) {
        return userTenantId === entityOwnerTenantIds;
      }

      return _.some(entityOwnerTenantIds, function someTenants(tenantId) {
        return userTenantId === tenantId;
      });
    }

    /**
     * Determines whether the provided tenantId is the ancestor for all the provided tenants.
     *
     * @param    {String}              tenantId            The tenant id.
     * @param    {String | String[]}   tenantIdOrIds       The tenantId or tenantIds list.
     * @returns  {Boolean}  True if provided tenantId is the ancestor else false.
     */
    function isTenantsAncestor(tenantId, tenantIdOrIds) {
      if (!tenantId) {
        return true;
      }

      return (_.isArray(tenantIdOrIds) ? tenantIdOrIds : [tenantIdOrIds]).every(id => {
        return !!(id || '').match(tenantId || undefined);
      });
    }

    /**
     * launch create/edit user workflow in modal
     *
     * @method   modifyUserModal
     * @param    {Object}   [principal=undefined]   The principal to edit
     * @return   {Object}   Promise resolved with newly created or modified
     *                      principal else rejected when modal closed.
     */
    function modifyUserModal(principal) {
      var modalConfig = {
        size: 'xl',
        controller: 'modifyPrincipalCtrl as $ctrl',
        templateUrl: 'app/admin/access-management/user-groups/modify.html',
        resolve: {
          pageConfig: function getPageConfig() {
            if (principal) {
              return {
                inModal: true,
                editMode: true,
                domain: principal.domain,
                type: principal.type,
                name: principal.name,
                tenantId: principal.tenantId,
              };
            }

            return {
              inModal: true,
              createMode: true,
              type: 'user',
            };
          },
        },
      };

      return SlideModalService.newModal(modalConfig)
        .then(function createdPrincipalSuccess(newPrincipal) {
          // Handles the case for both local or AD user creation.
          if (newPrincipal.domain === 'LOCAL') {
            return transformUser(newPrincipal);
          } else {
            // While creating an AD/SSO user, the newly created user/groups are
            // present inside the newPrincipal as 'principals'. This array of
            // principals doesn't have the basic details like roles, restricted
            // etc. which is why we are extending every AD principal with those
            // details and transforming every principal too.
            var extraPrincipalDetails = _.pick(newPrincipal,
              ['roles', 'restricted']);
            return _.assign(newPrincipal, {
              principals: _.map(newPrincipal.principals,
                function transformPrincipal(principal) {
                  return transformUser(_.assign(principal,
                    extraPrincipalDetails));
                }),
            });
          }
        });
    }

    /**
     * Opens the user settings modal for a specific user.
     *
     * @method    userSettingsModal
     * @return   {Object}   Promise resolved with modified principal else
     *                      rejected when modal closed.
     */
    function userSettingsModal() {
      var user = $rootScope.user;

      var modalConfig = {
        size: 'xl',
        controller: 'modifyPrincipalCtrl as $ctrl',
        templateUrl: 'app/admin/access-management/user-groups/modify.html',
        ariaLabelledBy: 'c-access-management-header',
        resolve: {
          pageConfig: function getPageConfig() {
            return {
              // Set hideHeader to true to show a different header.
              hideHeader: true,

              inModal: true,
              editMode: true,
              domain: user.domain,
              type: 'user',
              name: user.username,
              isSelfUpdate: true,
            };
          },
        },
      };

      return SlideModalService.newModal(modalConfig);
    }
    /**
     * launch view user details in modal
     *
     * @method   modifyUserModal
     * @param    {Object}   [principal=undefined]   The principal details to see
     * @return   {Object}   Promise resolved when provided principal is either
     *                      removed or edited else rejected when closed.
     */
    function viewUserDetailsModal(principal) {
      var modalConfig = {
        size: 'xl',
        controller: 'viewPrincipalController as $ctrl',
        templateUrl: 'app/admin/access-management/user-groups/view.html',
        resolve: {
          pageConfig: function getPageConfig() {
            return {
              inModal: true,
              viewMode: true,
              domain: principal.domain,
              type: principal.type,
              name: principal.name,
              sid: principal.sid,
            };
          },
        },
      };

      return SlideModalService.newModal(modalConfig);
    }

    /**
     * Authenticate a User.
     *
     * @param      {Object}   credentials  username & password
     * @return     {object}   promise to resolve the request for
     *                        authentication
     */
    function authenticate(credentials) {
      return $http({
        method: 'post',
        url: '/login',
        data: credentials,
        headers: {'Login-Source': ['iris_ui']}
      }).then(
        function authenticateSuccess(response) {
          var heliosOnPremMode = _.get($rootScope, 'basicClusterInfo.mcmOnPremMode', false);
          // Only proceed with MFA, if the cluster level MFA setting is on.
          // and user is not exempted from MFA sign in.
          var mfaEnabled = heliosOnPremMode
            ? _.get(response.data, 'user.isAccountMfaEnabled')
            : _.get(response.data, 'user.isClusterMfaEnabled');
          var userExempt = _.get(response.data, 'user.mfaInfo.isUserExemptFromMfa', false);

          const hasMfaInfo = _.get(response.data, 'user.mfaInfo', false);
          const domain = _.get(response.data, 'user.domain', null);
          // Determining AD user based on domain.
          const isAdUser = (domain !== 'LOCAL');
          const isMfaEnabled = isAdUser ? Boolean(Object.keys(hasMfaInfo).length) : mfaEnabled;

          let canProceedMfa = false;

          if (isMfaEnabled && !userExempt) {
            // special case to handle for heliosOnPrem or OnPrem
            if (heliosOnPremMode) {
              canProceedMfa = FEATURE_FLAGS.mfaHeliosOnPrem;
            } else if (isAdUser) {
              canProceedMfa = FEATURE_FLAGS.mfaAdpSupport;
            } else {
              canProceedMfa = FEATURE_FLAGS.mfaLoginEnabled;
            }
          }

          if (canProceedMfa) {
            var mfaInfo = _.get(response.data, 'user.mfaInfo');
            var mfaMethods = _.get(response.data, 'user.mfaMethods', []);

            // Determine whether a new setup is needed.
            var isSetupDone = false;
            var isTotpSetupDone = _.get(mfaInfo, 'isTotpSetupDone', false);
            var isEmailOtpSetupDone = _.get(mfaInfo, 'isEmailOtpSetupDone', false);

            switch (true) {
              case mfaMethods.includes('totp') && mfaMethods.includes('email'):
                // special case for heliosOnPrem or OnPrem
                isSetupDone = isTotpSetupDone && (heliosOnPremMode || isEmailOtpSetupDone);
                break;
              case mfaMethods.includes('totp'):
                isSetupDone = isTotpSetupDone;
                break;
              case mfaMethods.includes('email'):
                isSetupDone = isEmailOtpSetupDone;
                break;
            }

            // MFA verification is need to complete
            // the user authentication.
            // Temporarily hold the user information so that we can
            // continue with MFA verification.
            NgUserStoreService.updateUser(response.data.user);
            localStorageService.set('mfaUserData', response.data);
            var onPremMfaRoute = isSetupDone ? 'mfa.verify' : 'mfa.setup';
            var heliosOnPremMfaRoute = isSetupDone ? 'helios-onprem-mfa-verify' : 'helios-onprem-mfa';
            // special case for heliosOnPrem or onPrem
            $state.go(heliosOnPremMode ? heliosOnPremMfaRoute : onPremMfaRoute, {}, { reload: true });

            return $q.resolve(null);
          }

          // if user loggin first time take them to change password page
          // TODO(hari): need to move this code to change-password.guard.ts
          const forcePasswordChange = _.get(response.data, 'user.forcePasswordChange', false);
          if (heliosOnPremMode) {
            if (forcePasswordChange) {
              localStorageService.set('changePasswordUserData', response.data);
              $state.go('change-password', { reload: true });
              return $q.resolve(null);
            }

            if ($state.current?.name !== 'app-pillars') {
              // TODO: Cannot check for FEATURE_FLAGS.appPillarsEnabled since
              // all flags return as false for unauthenticated /featureFlags endpoint.
              $state.go('app-pillars', { loginRedirect: true });
            }
          }

          let loginData = response.data;
          if (loginData.errorCode) {
            return $q.reject(response);
          }

          // clusterNow will not work here,
          // as the cluster API is not yet called
          loginData.loginTime = Date.now();

          // session creation is async so wait for it.
          return createSession(loginData).then(() => loginData);
        }
      );
    }

    /**
     * Triggers a redirection for authenticating with the Identity Provider
     *
     * @method   authenticateIDP
     * @param    {string}    tenantId   The Tenant ID for the Single Sign-On
     */
    function authenticateIDP(tenantId) {
      const postLoginRedirect = preLoginRequest;
      if (!postLoginRedirect.name) {
        postLoginRedirect.name = HOME_STATE.name;
        postLoginRedirect.params = $location.search();
      }
      localStorageService.set('postSsoLoginRedirect', postLoginRedirect);

      let loginUrl = API.public('idps/login');
      if (tenantId) {
        loginUrl += '?tenantId=' + tenantId;
      }
      $window.location.href = loginUrl;
    }

    /**
     * Logs a user out and destroys the user's session.
     *
     * @param {*} stateParams - query parameters to pass along to logout or login.
     * @returns Promise that resolves once logout is completed.
     */
    function logout(stateParams = {}) {

      // goto a special logout page for SSO/IDP users to prevent login loop
      // because of FEATURE_FLAGS.autoSsoRedirect
      var gotoLogoutPage = (isIdpUser() && isIdpLogoutPageEnabled()) ||
        isCertificateOnlyAuthentication() || isLoginBannerEnabled();

      // In IBM aqua mode, always goto the logout page for IDP users.
      if (isIdpUser() && NG_IS_IBM_AQUA_ENV) {
        gotoLogoutPage = true;
      }

      $rootScope.$broadcast('logout.initiated');
      return $http({
        method: 'post',
        url: '/logout'
      }).then(
        function logoutSuccess() {
          destroySession();
          $rootScope.$broadcast('logout.completed');
          $state.go(gotoLogoutPage ? 'logout' : 'login', stateParams);
    });
  }
    /**
     * Create a Helios Session for the current user.
     *
     * @method   createHeliosSession
     * @return   {object}    Returns the logged in user.
     */
    function createHeliosSession() {
      return userService.getMcmUser(true).then(function setSession(user) {
        // Creates the user session for the logged in user.
        userService.createSession(user);

        // Broadcast that the helios user has logged in
        NgBroadcastChannelService.postMessage('heliosUserLoggedIn');

        return user;
      });
    }

    /**
     * Get the MCM User.
     *
     * @method   getMcmUser
     * @param    {boolean}  force    Force fetchs the user information.
     * @return   {object}   promise of the response
     */
    function getMcmUser(force) {
      var data = getLocalStorageLoginData(false);

      if (!force && data) {
        return $q.resolve(data);
      } else {
        return $http.get(API.private('mcm/userInfo')).then(
          function processUserInfo(response) {
            return response.data;
          }
        );
      }
    }

    /**
     * Returns the MFA Configuration.
     *
     * @returns The current MFA configuration.
     */
    function getMfaConfig() {
      return $http.get(API.publicV2('mfa-config')).then(
        function processMfaInfo(response) {
          return response.data;
        }
      );
    }

    /**
     * Inform the user that he is being logged out.
     * The timeout is given so that the user can be informed the reason for
     * the log out.
     *
     * @param {string} messageKey
     */
    function informAndLogOutUser(messageKey) {
      logout();

      $timeout(function informUserOfLogout() {
        cMessage.error({
          textKey: messageKey || 'loggedOutOfHelios',
          persist: true,
        });
      }, 2000);
    }

    /**
     * Gets loginData from local storage.
     *
     * @param update  Update localStorage data if true.
     * @returns {{user}|*}  Login data.
     */
    function getLocalStorageLoginData(update) {
      let loginData = localStorageService.get('loginData');

      if (update) {
        var updateData = structuredClone(loginData);

        updateLocalStorage(updateData);
      }

      // Override loginData by impersonationData.
      // This is for support user impersonation so that support user can have the same
      // access as the impersonated account. The impersonation data are stored separately
      // to avoid polluting loginData in local storage.
      if (loginData && loginData.user) {
        _.merge(loginData.user, localStorageService.get(impersonationDataKey));
      }
      return loginData;
    }

    /**
     * Indicates if a user is already logged in or not, and ensures
     * appropriate information is setup
     *
     * @return     {boolean}  True if logged in, False otherwise.
     */
    function isLoggedIn() {
      if (!getLoginData()) {
        // User is possibly logged in but relevant data is missing, likely due
        // to a page refresh post login.
        let loginData = getLocalStorageLoginData(false);

        if (!loginData) {
          // User is definitely not logged in.
          return false;
        }

        createSession(loginData, true);
      }

      return !!getLoginData().user;
    }

    /**
     * Check if session user is the same as local storage user.
     *
     * @method   isSameAsRedirectUser
     * @return   {boolean}    Returns true if user is the same in the local
     * storage and from redirect (session API call).
     */
    function isSameAsRedirectUser() {
      return userService.getMcmUser(true).then(function checkSameUser(session) {
        let localStorage = getLocalStorageLoginData(false);

        return localStorage?.user?.sid === session?.user?.sid;
      });
    }

    /**
     * Determines whether the user is a Helios Tenant User.
     * - Currently this only applies to GCPBaaS User, When multi-tenancy
     *   on Helios is designed. We will redesign this.
     *
     * @method   isHeliosTenantUser
     * @return   {boolean}   True if Helios tenant user, False otherwise.
     */
    function isHeliosTenantUser() {
      // TODO(Sam): When Helios MultiTenancy is designed. Make sure the user
      // can indicate what type of profile the user belongs to.
      // Currently only the helios user has the property googleAccount filed.
      return _.get($rootScope, 'user.profiles[0].tenantType') === 'Mcm' ||
        (_.get($rootScope, 'user.googleAccount') && isTenantUser());
    }

    /**
     * Determines if viewing as tenant user either by impersonating a tenant
     * user or tenant user is logged in.
     *
     * @method   isTenantUser
     * @return   {boolean}   True if tenant user, False otherwise.
     */
    function isTenantUser() {
      return !!NgUserStoreService.getUserTenantId();
    }

    /**
     * Determines if the application is running in IBM BaaS mode.
     *
     * @returns True if application is running in IBM BaaS mode.
     */
    function isIbmBaasEnabled() {
      const deploymentType = _.get($rootScope,
        'basicClusterInfo.clusterDeploymentType');
      return deploymentType === 'kIBMBaaS' || FEATURE_FLAGS.kIBMBaaSEnabled;
    }

    /**
     * Determines if loggedIn user is a bifrost tenant user
     *
     * @method   isBifrostTenantUser
     * @return   {boolean}   True if bifrost tenant user, False otherwise.
     */
    function isBifrostTenantUser() {
      return isTenantUser() && _.get($rootScope.user, '_tenant.bifrostEnabled');
    }

    /**
     * Fetches the default bifrost connection id for a bifrost tenant user.
     *
     * @method   getDefaultBifrostConnectionId
     * @return   {number | null}   a int64 if tenant has a default bifrost connection, else null
     */
    function getDefaultBifrostConnectionId() {
      return isBifrostTenantUser() && _.get($rootScope.user, '_tenant._defaultBifrostConnection', undefined);
    }

    /**
     * Gets the list of organization that are available for the tenant to switch
     * to.
     *
     * @method   getOrgMemberships
     * @return   {Promise}   The list of available orgs.
     */
    function getOrgMemberships() {
      return getSessionUser().then(function gotSessionUser(user) {
        return user.orgMembership || [];
      });
    }

    /**
     * Determines whether the cluster is configured for Certificate only.
     *
     * @method   isCertificateOnlyAuthentication
     * @return   {boolean}   True if the cluster is set to certificate only
     *                       Authentication.
     */
    function isCertificateOnlyAuthentication() {
      var authenticationType = _.get($rootScope,
        'basicClusterInfo.authenticationType');
      return authenticationType === 'kCertificateOnly';
    }

    /**
     * Determines whether the user is logged-in using IDP/SSO.
     *
     * @method  isIdpUser
     * @return  {boolean}   True if user logged-in using IDP/SSO otherwise false
     */
    function isIdpUser() {
      return _.get(userService, 'user.idpUserInfo', false);
    }

    /**
     * Determines whether IDP is enabled in the cluster.
     *
     * @method  isIdpEnabled
     * @return  {boolean}   True if IDP is enabled else false.
     */
    function isIdpEnabled() {
      return FEATURE_FLAGS.idpEnabled &&
        ClusterService.basicClusterInfo.idpConfigured;
    }

    /**
     * Determines whether login banner is enabled of the cluster.
     *
     * @method   isLoginBannerEnabled
     * @return   {boolean}   True if login banner is enabled else false
     */
    function isLoginBannerEnabled() {
      return !!(FEATURE_FLAGS.loginBanner &&
        ClusterService.basicClusterInfo &&
        ClusterService.basicClusterInfo.bannerEnabled);
    }

    /**
     * Determines whether auto SSO/IDP login is enabled or not and if enabled
     * then going to "/<ip>/*" URL expect "/<ip>/login" (aka login backdoor) URL
     * will ask user to login via SSO/IDP always.
     *
     * @method  isIdpAutoLoginEnabled
     * @return  {boolean}   True if auto SSO/IDP login is enables else false.
     */
    function isIdpAutoLoginEnabled() {
      return isIdpEnabled() && FEATURE_FLAGS.autoSsoRedirect;
    }

    /**
     * Determines whether to show special SSO/IDP logout page on user logout.
     *
     * @method  isIdpLogoutPageEnabled
     * @return  {boolean}   True if we can show special SSO/IDP logout page else
     *                      return false.
     */
    function isIdpLogoutPageEnabled() {
      return isIdpEnabled() &&
        (FEATURE_FLAGS.autoSsoRedirect || FEATURE_FLAGS.idpLogoutPageEnabled);
    }

    /**
     * saves state name and params so user can be returned
     * to the requested state upon successful login
     *
     * @param      {string}  toState   To state
     * @param      {Object}  toParams  state parameters
     */
    function capturePreLoginRequest(toState, toParams) {
      preLoginRequest = {
        name: toState.name,
        params: toParams,
      };

      // This function is actually being used to capture request
      // the previous state visited when a user is logged out in
      // order to return them there upon login. Cache the
      // user sid if one is set so redirecting the user can be
      // avoided if a different user logs in.
      preLoginRequestUserSid = userSid;
    }

    /**
     * Contructs the redirect url using prelogin request details
     *
     * @method   getPreLoginRequestUrl
     * @return   {String}                returns prelogin requested redirect url
     */
    function getPreLoginRequestUrl() {
      var redirectUrl = preLoginRequest ? $state.href(preLoginRequest.name,
        preLoginRequest.params, {absolute: false}) : '';
      return redirectUrl;
    }

    /**
     * handles post login redirection, directing user to previously
     * requested page or the dashboard if no deeplink access was attempted
     */
    function redirectPostLogin() {
      if (!!preLoginRequestUserSid && preLoginRequestUserSid !== userSid) {
        // The user doesn't match previously logged sid so clear the request info.
        // Don't assume the newly logged in user is allowed the same access or is
        // interested in revisiting the same page.
        preLoginRequest = {};
      }

      const state = $state.get(preLoginRequest.name);

      // After upgrade, need to check whether the athena subnet is conflicting with other ips in the network
      // If there is a conflict, redirect it to update subnet page
      // otherwise, to respective state.
      const clusterInfo = _.get(ClusterService, 'clusterInfo');

      if (clusterInfo?.isAthenaSubnetClash) {
        $state.go('ng-apps-management');
      } else if (state) {
        $rootScope.goToEffectiveFallbackState(state, preLoginRequest.params);
      } else {
        $state.goHome();
      }

      // empty the preLoginRequest object
      preLoginRequest = {};
    }

    /**
     * broadcast login success message to update nav list bases of logged in
     * user accessibility.
     * login success when:-
     * 1. user hit sign-in button authorized & cluster config is fetched.
     * 2. user returns back with valid session cookie and cluster config is
     *    fetched from app.js appOnStateChangeStart state change watcher.
     *
     * @method   loginSuccess
     */
    function loginSuccess() {
      NgWorkflowAccessContextService.clearWorkflowAccessContext();
      $rootScope.$broadcast('login.success');
    }

    /**
     * Sets up the current session
     *
     * @param      {object}  newLoginData  The login data as returned from
     *                                     authentication call
     * @returns    Promise resolved on successful session creation else rejected with failure reason.
     */
    function createSession(newLoginData, isSessionResuming) {
      updateLoginData(newLoginData);
      let loginData = getLoginData();
      updateClusterFound(loginData.isNodeInCluster);

      configureApplicationForUser();
      _initiateActivityTracking();

      // Update the user information in the service.
      userService.user = loginData.user;
      userSid = loginData.user.sid;

      const promises = [
        // Fetch bifrost capabilities on login and when resuming session
        isBifrostTenantUser() ? updateBifrostCapabilities() : null,
        isBifrostTenantUser() ? updateDefaultBifrostConnection() : null,
        // Get user privileges only if node is in cluster.
        // If resuming a previous session, update privs from the API, as
        // localStorage privs might be stale, particular after an upgrade that
        // includes new privs.
        (clusterState.found && isSessionResuming) ? updateSessionUserPrivs() : null,
      ].filter(Boolean);

      return $q.all(promises).then(() => {
        // In case of refactoring user privs, update the user observable
        // only after privs have been fetched
        NgUserStoreService.updateUser(userService.user);
        return newLoginData;
      });
    }

    /**
     * Update the decorated login data in the local storage, $rootScope and
     * login data kept in Angular user store.
     *
     * @param    {object}  updatedLoginData  The new login data.
     */
    function updateLoginData(updatedLoginData) {
      NgUserStoreService.updateLoginData(updatedLoginData);
    }

    /**
     * configures the application according to logged in user.
     *
     * @method   configureApplicationForUser
     */
    function configureApplicationForUser() {
      // A common state for helios tenants for all cluster/single cluster mode
      if (isHeliosTenantUser()) {
        HOME_STATE.set('dashboards.organization');
        return;
      }
      // dashboard is not accessible for tenants or restricted user.
      if (isTenantUser() || NgUserStoreService.isRestrictedUser()) {
        // TODO(veetesh): remove this home state override when backend qualifies
        // dashboard API for tenant.
        HOME_STATE.set(isIbmBaasEnabled() ? 'dashboards.ibm-baas' : 'jobs');
      } else {
        HOME_STATE.resetToDefault();
      }
    }

    /**
     * adds a copy of the user to $rootScope as $rootScope.user.
     *
     * @return     {object}  The logged in user
     */
    function updateUserOnRootScope(loginData) {
      $rootScope.user =
        (loginData && loginData.user) ?
          cUtils.simpleCopy(loginData.user) : undefined;
    }

    /**
     * Updates the cluster identifiers in the current context.
     *
     * @param   {object}   clusterIdentifiers  list of clusters to be updated.
     */
    function updateClusterForUser(clusterIdentifiers) {
      $rootScope.user.clusterIdentifiers = (clusterIdentifiers || []).map(
        function generateClusterIdentifier(cluster) {
          return _.pick(cluster, ['clusterId', 'clusterIncarnationId']);
        }
      );
      NgUserStoreService.updateUser($rootScope.user);
      getLocalStorageLoginData(true);
    }

    /**
     * Remove unclaimed cluster references from user, local storage
     *
     * @method   removeUnclaimedClusters
     */
    function removeUnclaimedClusters(unclaimedClusterIds) {
      var data = getLoginData();

      var clusterIdentifiers = data.user.clusterIdentifiers.filter(
        clusterIdentifier => !unclaimedClusterIds.includes(clusterIdentifier.clusterId)
      );

      var updatedUser = _.assign({}, data.user, { clusterIdentifiers });
      updateLogindataUser(updatedUser);
    }

    /**
     * Retrieves the complete session user with privileges
     *
     * @method   validateSessionUser
     */
    function validateSessionUser() {
      var promises = {
        user: getSessionUser(),
        privileges: getSessionUserPrivs(),
      };

      return $q.all(promises).then(function mergeUserInfo(resps) {
        return _.assign(resps, { isNodeInCluster: true }) || {};
      });
    }

    /**
     * Get the current session user.
     *
     * @method   getSessionUser
     * @return   {object}    Promise with the logged in user or an error
     */
    function getSessionUser() {
      return $http({
        method: 'get',
        url: API.public('sessionUser'),
      }).then(
        function getPrivsSuccess(resp) {
          return resp.data || {};
        }
      );
    }

    /**
     * Gets the privileges for the current Helios user. NOTE: When cluster
     * switching via altClusterSelector, user privileges is got from passthrough.
     *
     * @method   getMcmUserPrivs
     * @return   {object}   Promise with the privileges of logged in Helios user.
     */
    function getMcmUserPrivs() {
      return $http({
        method: 'get',
        url: API.mcm('users/privileges')
      }).then(
        function getMcmPrivsSuccess(resp) {
          return resp.data || [];
        }
      );
    }

    /**
     * Gets the privileges for the current session user. NOTE: When cluster
     * switching via altClusterSelector, current session user is the user
     * registered via the remote cluster's registration.
     *
     * @method   getSessionUserPrivs
     */
    function getSessionUserPrivs() {
      return $http({
        method: 'get',
        url: API.public('users/privileges'),
      }).then(
        function getPrivsSuccess(resp) {
          return resp.data || [];
        }
      );
    }

    /**
     * Update Impersonation data in local storage after impersonation.
     *
     * @method   updateImpersonationUser
     */
    function updateImpersonationUser(clusterIdentifiers) {
      return getSessionUser().then(function gotSessionUser(user) {
        var impersonationData = {
          profiles: user.profiles,
          subscriptionInfo: user.subscriptionInfo
        };
        var salesforceAccount = user.salesforceAccount || {};

        impersonationData.salesforceAccount = {
          isDGaaSUser: salesforceAccount.isDGaaSUser || false,
          isGaiaUser: salesforceAccount.isGaiaUser || false,
          isDMaaSUser: salesforceAccount.isDMaaSUser || false,
          isDRaaSUser: salesforceAccount.isDRaaSUser || false,
          isRPaaSUser: salesforceAccount.isRPaaSUser || false,
        };
        impersonationData.roles = user.roles || [];
        impersonationData.privilegeIds = user.privilegeIds || [];
        impersonationData.clusterIdentifiers = clusterIdentifiers || [];
        localStorageService.set(impersonationDataKey, impersonationData);
      });
    }

    /**
     * Update privs for current tenant user privs
     *
     * @method   updateSessionUserPrivs
     */
    function updateSessionUserPrivs() {
      var sessionApiFn = _.get(ClusterService.basicClusterInfo, 'mcmMode') ?
        getMcmUserPrivs: getSessionUserPrivs;
      return sessionApiFn().then(function gotPrivs(userPrivs) {
        let updatedLoginData = _.assign(
          getLoginData(), { privileges: userPrivs });

        updateLoginData(updatedLoginData);
      });
    }

    /**
     * Update bifrostCapabilities for current tenant user
     *
     * @method   updateSessionUserPrivs
     */
    function updateBifrostCapabilities() {
      const tenantId = getLoginData().user.tenantId;
      return TenantService.getBifrostTenantsCapabilities(tenantId).then(
        ({tenantCapabilities, clusterCapabilities}) => {
        const newLoginData = getLoginData();
        Object.assign(newLoginData.user._tenant, {
          _bifrostCapabilities: tenantCapabilities[tenantId] || {}
        });
        Object.assign(newLoginData.user, {
          _clusterCapabilities: clusterCapabilities || {}
        });
        updateLoginData(newLoginData);
      });
    }

    /**
     * Updates default bifrost connection id for a bifrost enabled
     * tenant user.
     *
     * @method   updateDefaultBifrostConnection
     */
    function updateDefaultBifrostConnection() {
      const tenantId = getLoginData().user.tenantId;

      return TenantService.getDefaultBifrostConnection(tenantId).then(
        (decoratedBifrostConnection) => {
          // assign only if there is a valid information
          if (!!decoratedBifrostConnection) {
            const newLoginData = getLoginData();
            Object.assign(newLoginData.user._tenant, {
              _defaultBifrostConnection: decoratedBifrostConnection.id
            });
            updateLoginData(newLoginData);
          }
        }
      );
    }

    /**
     * Update user privs based on the intersection of the local user's privs and
     * that of the registered remote access cluster user. NOTE: This function
     * assumes the clusterId for the remote access cluster is being passed via
     * the request header.
     *
     * Also, it updates the privs if a user impersonates the tenant with the
     * same logic.
     *
     * Also update restricted, roles and tenantId of the user from the new user
     *
     * @method updateSessionUser
     * @params {Object}  updatedConfig  The updated config in case the cluster context
     *                                  is switched
     */
    function updateSessionUser(updatedConfig) {
      var isImpersonated = !!NgTenantService.impersonatedTenant;
      var isHeliosTenantLoggedIn = isHeliosTenantUser();

      if (environment.heliosInFrame) {

        // For impersonation cases on helios and inside iframe, fetch fresh privileges
        if (isImpersonated) {
          return fetchAndUpdateSessionUser(true);

        // For non impersonation cases inside iframe, use logged in user privs
        } else {
          return $q.resolve($rootScope.user.privs);
        }
      }

      // Using $injector since injecting this normally causes circular dependency error
      var ctx = $injector.get('NgIrisContextService')?.irisContext;
      var isAllClusterMode = isAllClustersScope(ctx);
      var isMcm = $rootScope.basicClusterInfo.mcmMode;

      if (!localUserPrivs) {
        /* Convert and cache the local users privs as an array for easy
         * intersection computation.
         */
        localUserPrivs = Object.keys($rootScope.user.privs);
      }

      /* If running in multi-cluster-mode (Helios) then resolve with current
       * user's privs, as we don't ever want to update the privs. This is
       * because of the strong possibility of missing privs from the remote
       * cluster if its running an older version. Remote cluster can only be
       * registered with Helios by an admin, so all privs are safely assumed.
       */
      if (isMcm &&

        // Not a helios tenant user
        !isHeliosTenantLoggedIn &&

        // User has impersonated a tenant
        !isImpersonated &&

        // User has switched back from an impersonation. If NgTenantService.impersonatedTenant
        // is false, but this.didImpersonate is true, it means, user was impersonating earlier
        // and now has switched back to non impersonation mode
        !this.didImpersonate) {

        return $q.resolve($rootScope.user.privs);
      }

      var switchImpersonationUserData = Promise.resolve();
      // However, if its an impersonation case on Helios, allow priv intersection.
      if (isMcm && (isImpersonated || this.didImpersonate)) {

        // Save the fact that user is impersonating. Will be used later when user
        // switches out of impersonation.
        this.didImpersonate = isImpersonated;

        switchImpersonationUserData = this.updateUserLoginData();

        if (isAllClusterMode) {
          return switchImpersonationUserData;
        }
      }

      // Helios Tenant has logged in (not impersonated)
      if (isHeliosTenantLoggedIn) {
        let loginData = structuredClone(getLoginData());
        loginData.user.tenantId = _.get($rootScope, 'user.profiles[0].tenantId');
        updateLoginData(loginData);

        if (isAllClusterMode) {
          return $q.resolve($rootScope.user.privs);
        }
      }

      return switchImpersonationUserData.then(
        () => fetchAndUpdateSessionUser(isMcm, localUserPrivs)
      );
    }

    /**
     * Make an api call to fetch the session user and update the local replica
     * @params  {boolean} isMcm  Indicates if the context is helios
     * @params  {object}  localUserPrivs  existing privileges of logged in user
     * @returns Promise to be resolved with updated session user
     */
    function fetchAndUpdateSessionUser(isMcm, localUserPrivs) {
      var isMcm = $rootScope.basicClusterInfo.mcmMode;
      var isRunningInIframe = environment.heliosInFrame;
      var isImpersonatedInFrame = isRunningInIframe && NgTenantService.impersonatedTenantId;

      return $q.all({
        user: getSessionUser(),
        privs: isMcm && !isRunningInIframe ? $q.resolve({}) :
          (isImpersonatedInFrame ? getMcmUserPrivs() : getSessionUserPrivs()),
      }).then(
        function getUserSuccess({ user, privs }) {
          let loginData = structuredClone(getLoginData());
          let updatedLoginData;

          if (isMcm) {
            updatedLoginData = Object.assign({}, loginData);
            updatedLoginData.user.orgMembership = user.orgMembership;

            if (!isHeliosTenantUser()) {
              // Whatever we have in tenant-service, append that in user object.
              updatedLoginData.user.tenantId = NgTenantService.impersonatedTenantId;
            }

            // When impersonating inside iframe, blindly use the fetched privileges.
            // Otherwise get effective privileges (since cluster impersonation is always
            // using 'admin' except in certain cases. Read doc for 'getEffectiveUserPrivs') .
            if (isRunningInIframe) {
              updatedLoginData.privileges = privs;
            }

          } else {
            var { roles, tenantId, restricted, orgMembership, previousLoginTimeMsecs } = user;
            updatedLoginData = _.assign(loginData, {
              privileges: getEffectiveUserPrivs(privs, localUserPrivs),
              user: _.assign(loginData.user, { roles, tenantId, restricted, orgMembership, previousLoginTimeMsecs })
            });
          }

          updateLoginData(updatedLoginData);
          configureApplicationForUser();

          if (isBifrostTenantUser()) {
            return $q.all({
              bifrostCapabilities: updateBifrostCapabilities(),
              defaultBifrostConnection: updateDefaultBifrostConnection()
            });
          }
        }
      );
    }

    /**
     * Updates the previleges/roles/profiles of the logged in user
     * @returns promise to be resolved with updated user data
     */
    function updateUserLoginData() {
      return this.getMcmUser(true).then(function getUserSuccess(response) {
        if (!localUserPrivs) {
          localUserPrivs = Object.keys($rootScope.user.privs);
        }

        var { user, privileges } = response;
        let loginData = structuredClone(getLoginData());
        let updatedLoginData = _.assign(loginData, {
          privileges: getEffectiveUserPrivs(privileges, localUserPrivs),
          user: _.assign(loginData.user, {
            roles: user.roles,
            tenantId: NgTenantService.impersonatedTenantId,
            profiles: user.profiles,
            mcmEula: $rootScope.user.mcmEula,
          })
        });

        if (!NgTenantService.impersonatedTenant) {
          delete updatedLoginData.user.tenantId;
        }

        updateLoginData(updatedLoginData);
        configureApplicationForUser();
      });
    }

    /**
     * Return the effective privileges for the user viewing a remote cluster or
     * impersonating a tenant.
     *
     * 1. A less privileged user of the access cluster should not get more
     *    privileges when viewing a remote cluster using SPOG eg.
     *    If the logged in user can't see protection jobs page in the access
     *    cluster then he should not be able see protection jobs page for the
     *    remote cluster which maybe connected using admin credentials which
     *    will have PROTECTION_VIEW privilege.
     * 2. During impersonation we should keep some tenant only privileges if it
     *    is present eg.
     *    If HYX is enabled for the tenant from edit tenants page then during
     *    impersonation HYBRID_EXTENDER_VIEW & similar privileges should be
     *    preserved from getting filter out because of above logic.
     *
     * @method   getEffectiveUserPrivs
     * @param    {Array}   newPrivileges   The list of new privileges.
     * @param    {Array}   oldPrivileges   The list of old privileges.
     * @returns  {Array}   The list of effective privileges.
     */
    function getEffectiveUserPrivs(newPrivileges, oldPrivileges) {
      // list of privileges which will be available only for a tenant user.
      var tenantOnlyPrivs = [
        'HYBRID_EXTENDER_DOWNLOAD',
        'HYBRID_EXTENDER_VIEW',
      ];

      // For McM cases, new privileges are paramount
      if ($rootScope.basicClusterInfo.mcmMode) {
        return newPrivileges;
      }

      // If the tenant account is switchable override the existing privs
      // altogether.
      return TenantService.isTenantAccountSwitchable() ?
        newPrivileges : [].concat(
        /**
         * The new privs will be the intersection of the logged in local
         * user's privs and the privs of the user used with the remote
         * cluster registration, converted to a hashmap so UI lookups
         * function as expected.
         */
        _.intersection(oldPrivileges, newPrivileges),

        /**
         * keep tenant only privileges when SP admin is impersonating as a
         * tenant these privileges are getting lost in above intersection.
         */
        _.intersection(newPrivileges, tenantOnlyPrivs)
      );
    }

    /**
     * Create a new User
     *
     * @method     createUser
     *
     * @param      {Object}   newUser  to be created
     * @return     {object}   to resolve the request, resolves with user object,
     *                        rejects with raw server response
     */
    function createUser(newUser) {
      return $http({
        method: 'post',
        url: usersEndpoint,
        data: untransformUser(newUser),
      }).then(function createUserSuccess(resp) {
        return resp.data || {};
      });
    }

    /**
     * Get the user
     *
     * @method     getUser
     * @param      {Object}   params    to constrain query
     * @return     {object}   to resolve the request,
     *                        resolves with server response
     */
    function getUser(params) {
      _.assign(params, {
        partialMatch: false,
      }, {});
      cUtils.selfOrDefault(params, 'allUnderHierarchy', !!params.usernames);
      return $http({
        method: 'get',
        url: usersEndpoint,
        params: params,
      }).then(
        function getUserSuccess(response) {
          // Currently, the v1 API doesn't have the sid param, so filter the
          // response for the sid, return the first user if sid not present.
          var user = response.data &&
            (_.find(response.data, ['sid', params._sid]) || response.data[0]);
          user = transformUser(user);
          updateLogindataUser(user);
          return user;
        }
      );
    }

    /**
     * Get the list of users in the tenant for the given tenant ids.
     *
     * @method   getTenantUsers
     * @param    {Array}    tenantIds   The IDs of tenant to get.
     * @return   {Object}   Promise resolved with found tenant else rejected
     *                      with error
     */
    function getTenantUsers(tenantIds) {
      var promises = {
        allRoles: getAllRoles(),
        tenantUsers: $http({
          method: 'GET',
          url: usersEndpoint,
          params: { tenantIds: tenantIds },
        }),
      };

      return $q.all(promises).then(function gotTenantUsers(resp) {
        return transformUsersList(resp.tenantUsers.data, resp.allRoles);
      });
    }


    /**
     * Get Tenant groups.
     *
     * @method     getTenantGroups
     * @return     {Promise}  Q promise carrying the server's response
     */
    function getTenantGroups(tenantIds) {
      var promises = {
        allRoles: getAllRoles(),
        tenantGroups: GroupService.getGroups({
          tenantIds: tenantIds || [],
        }),
      };

      return $q.all(promises).then(function gotTenantGroups(resp) {
        return transformUsersList(resp.tenantGroups, resp.allRoles);
      });
    }

    /**
     * Get the list of all principals including users & groups.
     *
     * @method     getAllPrincipals
     * @return     {Promise}  The promise returns the list of all principals.
     */
    function getAllPrincipals() {
      const params = { allUnderHierarchy: true };

      return $q.all([getAllUsers(params), GroupService.getGroups(params)])
        .then(([users, groups]) => [...users, ...groups]);
    }

    /**
     * Get all users
     *
     * @method     getAllUsers
     * @return     {object}   to resolve the request,
     *                        resolves with server response
     */
    function getAllUsers(params) {
      params = params || {};
      return $http({
        method: 'get',
        url: usersEndpoint,
        params: params,
      }).then(function transformGetAllUsersResponse(response) {
        var users = (response.data || []).map(transformUser);

        if(params._includeTenantInfo) {
          return TenantService.resolveTenantDetails(users);
        }
        return users;
      });
    }

    /**
     * update a user's information
     *
     * @method     updateUser
     * @param      {Object}   user      updated user information
     * @return     {object}   to resolve the request, resolves with server
     *                        response
     */
    function updateUser(user) {
      return $http({
        method: 'put',
        url: usersEndpoint,
        data: untransformUser(user),
      }).then(
        function successFn(r) {
          var updatedUser = transformUser(r.data || {});
          updateLogindataUser(updatedUser);
          return updatedUser;
        }
      );
    }

    /**
     * Update login data with updated/latest user object if its for loggedin user.
     *
     * @param {Object}   updatedUser      updated user information.
     */
    function updateLogindataUser(updatedUser) {
      NgUserStoreService.updateLogindataUser(updatedUser);
    }

    /**
     * delete a list of users
     *
     * @method     deleteUsers
     * @param      {Object}   params  hash of user objects to be deleted
     * @return     {object}   to resolve the request, resolves with server
     *                        response
     */
    function deleteUsers(params) {
      return $http({
        method: 'delete',
        url: usersEndpoint,
        data: params,
      });
    }

    /**
     * Gets the restricted protection sources for the provided sids
     *
     * @param      {array}    sids    The sids to retrieve the accessible
     *                                sources for
     * @return     {object}   to resolve the request, resolves with array of
     *                        protection sources, rejects with raw server
     *                        response
     */
    function getProtectionSources(sids) {
      return $http({
        method: 'get',
        url: protectionSourcesEndpoint,
        params: {
          sids: sids,
        },
      }).then(function getProtectionSourcesSuccess(resp) {

        return (resp.data || []).map(function loopSids(sid) {
          sid.protectionSources = (sid.protectionSources || [])
            .map(SourceService.publicEntityToPrivateEntity);
          sid.views = sid.views || [];
          return sid;
        });

      });
    }

    /**
     * updates the list of restricted protection sources for the provided sids
     *
     * NOTE: the backend wants an array of object, one object per sid, as a
     * means of allowing different sources/views to be defined for different
     * sids. The UI was not designed/built with such an approach, so all sids
     * get the same set of sourceIds/viewNames
     *
     * @param      {array}    sids       The sids to create/update sources for
     * @param      {array}    sourceIds  The source ids to grant access to for
     *                                   the provided sid
     * @param      {array}    viewNames  The view names to grant access to for
     *                                   the provided sid
     * @return     {object}   to resolve the request, resolves/rejects with raw
     *                        server response
     */
    function updateProtectionSources(sids, sourceIds, viewNames) {
      sids = [].concat(sids);
      return $http({
        method: 'put',
        url: protectionSourcesEndpoint,
        data: {
          sourcesForPrincipals: sids.map(function buildSidObj(sid) {
            return {
              sid: sid,
              protectionSourceIds: sourceIds,
              viewNames: viewNames,
            };
          }),
        },
      });
    }

    /**
     * Get all privileges
     *
     * @method     getAllPrivileges
     * @return     {object}   Q promise carrying the server's response
     */
    function getAllPrivileges() {
      return $http({
        method: 'get',
        url: API.public('privileges'),
      }).then(function transformGetPrivsResponse(response) {
        return response.data || [];
      });
    }

    /**
     * Get all MCM privileges
     *
     * @method     getAllMcmPrivileges
     * @return     {object}   Q promise carrying the server's response
     */
    function getAllMcmPrivileges() {
      return $http({
        method: 'get',
        url: API.mcm('privileges'),
      }).then(function transformGetPrivsResponse(response) {
        return response.data || [];
      });
    }

    /**
     * Create a new Role
     *
     * @method     createRole
     * @param      {Object}   newRole  to be created
     * @return     {object}   Q promise carrying the server's response
     */
    function createRole(newRole) {
      return $http({
        method: 'post',
        url: rolesEndpoint,
        data: newRole
      }).then(function transformCreateRoleResponse(response) {
        return response.data || {};
      });
    }

    /**
     * Get a single Role
     *
     * @method     getRole
     * @param      {Object}   params to constrain query
     * @return     {object}   Q promise carrying the server's response
     */
    function getRole(params) {
      return $http({
        method: 'get',
        url: rolesEndpoint,
        params: params
      }).then(function transformGetRoleResponse(response) {
        return transformRole(response.data[0] || {});
      });
    }

    /**
     * Get all Roles
     *
     * @method     getAllRoles
     * @param      {Object=}   params   API query params{
     *   allUnderHierarchy: True, if all roles under hierarchy are needed else
     *                      false.
     *   tenantIds: Roles filtered according to the tenantIds.
     * }
     * @return     {object}   Q promise carrying the server's response
     */
    function getAllRoles(params) {
      params = params || {};
      return $http({
        method: 'get',
        url: rolesEndpoint,
        params: params,
      }).then(function transformGetRoleResponse(response) {
        var roles = _.map(response.data, transformRole);

        if (params._includeTenantInfo) {
          return TenantService.resolveTenantDetails(roles, 'tenantIds');
        }

        return roles;
      });
    }

    /**
     * Transforms the role object.
     *
     * @method   trasnformRole
     * @param    {Object}   role   The role to be transformed.
     * @returns  {Object}   The transformed role.
     */
    function transformRole(role) {
      // Add a key in each role to know if the role is owned by the logged in
      // user.
      role._isRoleOwner = isEntityOwner(role.tenantId);

      return role;
    }

    /**
     * Update the Role's information
     *
     * @method     updateRole
     * @param      {Object}   role    updated role information
     * @return     {object}   Q promise carrying the server's response
     */
    function updateRole(role) {
      return $http({
        method: 'put',
        url: [rolesEndpoint, '/', role.name].join(''),
        data: role
      }).then(function transformEditRoleResponse(response) {
        return response.data || {};
      });
    }

    /**
     * Delete a list of Roles
     *
     * @method     deleteRoles
     * @param      {Object}   params  hash of role objects to delete
     * @return     {object}   Q promise carrying the server's response
     */
    function deleteRoles(params) {
      return $http({
        method: 'delete',
        url: rolesEndpoint,
        data: params
      }).then(function transformDeleteRoleResponse(response) {
        return response.data || {};
      });
    }

    /**
     * Determines if this is the local admin
     *
     * @method     isThisLocalAdmin
     * @param      {Object}   user    The user
     * @return     {boolean}  True if this local admin, False otherwise.
     */
    function isThisLocalAdmin(user) {
      return user.username === 'admin' && user.domain === 'LOCAL';
    }

    /**
     * Determines if this is the logged in user
     *
     * @method     isThisLoggedInUser
     * @param      {Object}   user    The user
     * @return     {boolean}  True if this logged in user, False otherwise.
     */
    function isThisLoggedInUser(user) {
      return user.sid === getLoginData()?.user.sid;
    }

    /**
     * Determines if this is User is deletable.
     *
     * @method     _isThisUserDeletable
     * @param      {Object}     user    The user
     * @return     {Boolean}    True if deletable.
     */
    function _isThisUserDeletable(user) {
      return !user._isBuiltIn && !isThisLocalAdmin(user) &&
        !isThisLoggedInUser(user);
    }

    /**
     * Transform list of provided users and optionally resolve role with there
     * display name if allRoles are provided.
     *
     * @method   transformUsersList
     * @param    {Array}   usersList     The users list
     * @param    {Array}   [allRoles]    List of all roles used to resolve user
     *                                   role display name
     * @return   {Array}   Transformed Users list
     */
    function transformUsersList(usersList, allRoles) {
      var rolesMap = _.keyBy(allRoles, 'name');
      return (usersList || []).map(function eachUser(user) {
        return transformUser(user, rolesMap);
      });
    }

    /**
     * private service function for transforming users for UI friendly means
     *
     * @method     transformUser
     *
     * @param     {Object}  user          one user object
     * @param     {Array}   [rolesMap]    The map of all roles used to resolve
     *                                    user role display name.
     * @return    {Object}  transformed user object
     */
    function transformUser(user, rolesMap) {
      if (!user) {
        return {};
      }

      // Copy 'username' prop to 'name' prop so model is consistent between
      // LOCAL and AD Users. Also handles the special case when an AD user
      // or group is created, the name comes as 'fullName'. For SSO user, the
      // name comes as 'principalName'.
      user.name = user.name || user.username || user.fullName ||
        user.principalName;

      // Handles the case when objectClass is not defined, the default type is
      // user.
      user.type = user.objectClass === 'kGroup' ? 'group' : 'user';

      if (!user.hasOwnProperty('isAccountLocked') && user.hasOwnProperty('locked')) {
        // V2 APIs have a field called "locked", which is called "isAccountLocked"
        // in V1 APIs. Convert "isAccountLocked" to "locked" to make sure
        // workflows expecting V1 response still work.
        user.isAccountLocked = user.locked;
      }

      if (!user.hasOwnProperty('locked') && user.hasOwnProperty('isAccountLocked')) {
        // V1 APIs have a field called "isAccountLocked", which is called "locked"
        // in V2 APIs. Convert "locked" to "isAccountLocked" to make sure
        // workflows expecting V2 response still work.
        user.locked = user.isAccountLocked;
      }

      user.domain = NgUserStoreService.sanitizeDomain(user.domain);
      user.roles = user.roles || [];

      user._isBuiltIn = user.domain === 'LOCAL' && BUILT_IN_USERS.includes(user.name);
      user._isLocalAdmin = isThisLocalAdmin(user);
      user._isLoggedInUser = isThisLoggedInUser(user);
      user._isDeletable = _isThisUserDeletable(user);

      // resolve user role name with there display name when rolesMap are
      // provided.
      if (!_.isEmpty(rolesMap)) {
        user._stringifiedRoles = user.roles.map(function eachRole(role) {
          return rolesMap[role].label;
        }).join(', ');
      }

      // If S3 Keys not returned in API response, then show asterisks.
      user.s3AccessKeyId = user.s3AccessKeyId || asterisks;
      user.s3SecretKey = user.s3SecretKey || asterisks;

      // Set internal flag to indicate whether a primary group has been set.
      user._hasPrimaryGroup = !!user.primaryGroupName;

      return user;
    }

    /**
     * sanitizes a user object for API submission
     *
     * @method     untransformUser
     * @param      {Object}  user    The user
     * @return     {Object}  updated user object
     */
    function untransformUser(user) {

      user = user || {};

      // Only admins can update effective Time and also
      // do not update effectiveTimeMsecs for admin user
      if (!getLoginData()?.privileges.includes('PRINCIPAL_MODIFY') ||
        user.username === 'admin') {
        delete user.effectiveTimeMsecs;
      }

      // Remove password fields if they're empty
      if (angular.isDefined(user.password) && !user.password) {
        delete user.password;
        delete user.passwordConfirm;
      }

      return user;
    }

    /**
     * clears the current session
     */
    function destroySession() {
      NgUserStoreService.clearLoginData()
      localUserPrivs = undefined;

      userService.user = undefined;

      updateUserOnRootScope(undefined);

      // empty the preLoginRequest object
      preLoginRequest = {};
      clearLocalStorage();
      _cleanupTransition();
      $timeout.cancel(activityTimeoutPromise);
      $rootScope.$broadcast('sessionDestroyed');
      NgBroadcastChannelService.postMessage('sessionDestroyed');
      cMessage.info({
        textKey: 'youHaveBeenLoggedOut'
      });
    }

    /**
     * updates localStorage values and their related angular injectable values
     * (as defined in values.js). if loginCompleted is explicitly false then
     * this function doesn't set all localStorage properties. This prevents the
     * user from circumventing EULA and License by accessing an alternative URL
     * before completing agreements, etc
     *
     * @param      {loginData}  loginData  as returned from authenticate() with
     *                                     mutations
     */
    function updateLocalStorage(loginData) {
      if (!loginData) {
        clearLocalStorage();
        return;
      }
      // Do not sync to localstorage in case of impersonation
      // as impersonation is a temporary state
      if (!NgTenantService.impersonatedTenantId) {
        localStorageService.set('domain', loginData.user.domain);
        localStorageService.set('loginData', loginData);
      }
    }

    /**
     * clears values in local storage and calls functions to update the
     * related injectable angular vars
     */
    function clearLocalStorage() {
      localStorageService.remove('loginData');
      localStorageService.remove('activityMsecs');
      localStorageService.remove('selectedCluster');
      localStorageService.remove('salesforceAccount');
      localStorageService.remove('numOpenedTabs');
      localStorageService.remove('bannerAccepted');
      localStorageService.remove('cohesityBannerAccepted');
    }

    /**
     * updates the angular injectable clusterState var based on provided boolean
     *
     * @param      {boolean}  clusterFound  true if the current node part of a
     *                                      cluster, false otherwise
     */
    function updateClusterFound(clusterFound) {
      clusterState.found = !!clusterFound;
    }

    /**
     * Generates new S3 Secret Access Key.
     *
     * @method     generateNewS3SecretKey
     *
     * @param      {Object}  data    The post data
     * @return     {String}  The newly generated key
     */
    function generateNewS3SecretKey(data) {
      return $http({
        method: 'post',
        url: API.public('users', 's3SecretKey'),
        data: data,
      }).then(function transformS3SecretKey(response) {
        return response.data && response.data.newKey || '';
      });
    }

    /**
     * Delete selected principals and update list
     *
     * @method     deletePrincipals
     *
     * @param      {Object}    principalsToDelete  Hash of principals to delete
     * @param      {Function}  successFn           An optional callback function
     */
    function deletePrincipals(principalsToDelete, successFn) {
      var modalTitleKey;
      var modalTextKey;
      var cMessageTitleKey;
      var cMessageTextKey;
      var contentString;
      var options;

      var userDomainsForDelete = {};
      var groupDomainsForDelete = {};
      var usersToDelete = 0;
      var groupsToDelete = 0;
      var tenantId;
      var sids = [];
      var names = [];
      var promises = [];

      // Add each principal to a hash of users or groups per domain.
      angular.forEach(principalsToDelete, function addToDeleteList(obj, sid) {
        sids.push(sid);
        names.push(obj.name + ' (' + obj.domain + ')');

        if (!tenantId) {
          tenantId = obj.tenantId;
        }

        if (obj.type === 'user') {
          // Form a new domain group if it's not present
          if (!userDomainsForDelete[obj.domain]) {
            userDomainsForDelete[obj.domain] = {
              domain: obj.domain,
              users: [],
              tenantId: tenantId,
            };
          }

          // Add user name to the hash of users per domain
          userDomainsForDelete[obj.domain].users.push(obj.name);

          // Increment the counter for testing total number of users to delete.
          // We cannot simply test the length of userDomainsForDelete because it
          // is a hash with multiple objects, each with its own users array. In
          // order to test total number of users we would have to inspect and
          // test the array of each child object. Same with groupsToDelete,
          // below.
          usersToDelete++;

        } else {
          if (!groupDomainsForDelete[obj.domain]) {
            groupDomainsForDelete[obj.domain] = {
              domain: obj.domain,
              names: [],
            };
          }

          groupDomainsForDelete[obj.domain].names.push(obj.name);
          groupsToDelete++;
        }
      });

      /*
       * Select the content to display in the confirmation modal
       * and the success cmessage
       */
      if (usersToDelete && !groupsToDelete) {
        // Deleting only users
        if (usersToDelete === 1) {
          modalTitleKey = 'access.modal.specific.user.delete.title';
          modalTextKey = 'access.modal.specific.user.delete.text';
          cMessageTitleKey = 'access.cMessages.deleted.specific.user.title';
          cMessageTextKey = 'access.cMessages.deleted.specific.user.text';

          if (tenantId) {
            modalTitleKey = 'access.modal.specific.tenantUser.delete.title';
            cMessageTitleKey =
              'access.cMessages.deleted.specific.tenantUser.title';
            cMessageTextKey =
              'access.cMessages.deleted.specific.tenantUser.text';
          }
        } else {
          modalTitleKey = 'access.modal.specific.users.delete.title';
          modalTextKey = 'access.modal.specific.users.delete.text';
          cMessageTitleKey = 'access.cMessages.deleted.specific.users.title';
          cMessageTextKey = 'access.cMessages.deleted.specific.users.text';
        }
      } else if (groupsToDelete && !usersToDelete) {
        // Deleting only groups
        if (groupsToDelete === 1) {
          modalTitleKey = 'access.modal.specific.group.delete.title';
          modalTextKey = 'access.modal.specific.group.delete.text';
          cMessageTitleKey = 'access.cMessages.deleted.specific.group.title';
          cMessageTextKey = 'access.cMessages.deleted.specific.group.text';
        } else {
          modalTitleKey = 'access.modal.specific.groups.delete.title';
          modalTextKey = 'access.modal.specific.groups.delete.text';
          cMessageTitleKey = 'access.cMessages.deleted.specific.groups.title';
          cMessageTextKey = 'access.cMessages.deleted.specific.groups.text';
        }
      } else {
        // Deleting a batch of users and groups
        modalTitleKey = 'access.modal.hybrid.delete.title';
        modalTextKey = 'access.modal.hybrid.delete.text';
        cMessageTitleKey = 'access.cMessages.deleted.hybrid.title';
        cMessageTextKey = 'access.cMessages.deleted.hybrid.text';
      }

      options = {
        titleKey: modalTitleKey,
        titleKeyContext: {count: names.length},
        contentKey: modalTextKey,
        contentKeyContext: {principals: names.join(', ')},
        actionButtonKey: 'delete',
        closeButtonKey: 'cancel',
        buttonIds: {
          'ok': 'confirm-delete-user',
          'cancel': 'cancel-delete-user',
        }
      };

      // Display the modal
      return cModal.standardModal({}, options).then(
        function processDeleteProtectionSources() {
          // Delete restricted access to protectionSources
          // before deleting the user/group
          return updateProtectionSources(sids, [], []);
        }
      ).then(
        function processDeletePrincipals(r) {
          // Create a separate promise for each domain from which to delete
          // principals
          angular.forEach(userDomainsForDelete,
            function forEachUserDomainsForDelete(userObj) {
              promises.push(deleteUsers(userObj));
            });

          angular.forEach(groupDomainsForDelete,
            function forEachGroupDomainsForDelete(groupObj) {
              promises.push(GroupService.deleteGroups(groupObj));
            });

          return $q.all(promises).then(
            function deletePrincipalsSuccess(response) {
              cMessage.success({
                titleKey: cMessageTitleKey,
                textKey: cMessageTextKey,
              });

              if (successFn) {
                successFn(principalsToDelete);
              }
            },
            evalAJAX.errorMessage
          );
        }
      );
    }

    /**
     * Initiates initial activity tracking, checking local storage value to
     * determine if its appropriate to logout the user based on inactivity.
     *
     * @method   _initiateActivityTracking
     */
    function _initiateActivityTracking() {
      var lastActivityMsecs;
      var activityDiffMsecs;

      // currently there is a race condition and re-establishing an existing
      // login happens before feature flags get loaded, so this check is invalid
      // in such scenarios.
      //
      // Using $injector since injecting this normally causes circular dependency error
      if (!FEATURE_FLAGS.inactivityLogoutEnabled || isMcm($injector.get('NgIrisContextService')?.irisContext)) {
        return;
      }

      // Check for previous activity in local storage and eject the user if
      // the activity threshold has been crossed.
      lastActivityMsecs = localStorageService.get('activityMsecs');
      if (lastActivityMsecs) {
        activityDiffMsecs = Date.now() - lastActivityMsecs;
        if (allowedInactivityMsecs < activityDiffMsecs) {
          // In this scenario, disable the messaging as the user won't
          // necessarily think anything of being logged in this context
          // (returning to the application or doing a hard reload).
          _inactivityLogout(true);
          return;
        }
      }

      _cleanupTransition = $transitions.onSuccess({}, registerNewActivity);
    }

    /**
     * Called on new User activity. Updates localStorage value, and cancels any
     * existing inactivity timeout promise before establishing a new one.
     *
     * @method   registerNewActivity
     */
    function registerNewActivity() {
      var acitvityMsecs = Date.now();

      // localStorage value to be checked on reload of application.
      localStorageService.set('activityMsecs', acitvityMsecs);

      if (activityTimeoutPromise) {
        $timeout.cancel(activityTimeoutPromise);
      }

      activityTimeoutPromise = $timeout(function promptInactivity() {
        cMessage.info({
          titleKey: 'inactivityLogout.interuptionTitle',
          textKey: 'inactivityLogout.interuptionText',
          acknowledgeTextKey: 'inactivityLogout.action',
          timeout: logoutDelayMs,
          persist: true,
        }).then(
          registerNewActivity,
          _inactivityLogout
        );
      }, allowedInactivityMsecs);
    }

    /**
     * Logs out a user based on inactivty
     *
     * @method   _inactivityLogout
     */
    function _inactivityLogout(suppressMessaging) {

      $log.info('Logging user out due to inactivity.');
      logout();

      if (suppressMessaging) {
        return;
      }

      // Pop cMessage in a timeout so the logout can complete before showing the
      // message. This allows for use without persist making cMessage close on
      // login.
      $timeout(function informUserOfLogout() {
        cMessage.info({
          textKey: 'inactivityLogout.loggedOutText',
        });
      }, 500);
    }

    /**
     * Get last login time
     *
     * @method     getLastLoginTime
     * @return     {string}   Last login time
     */
    function getLastLoginTime() {
      let dateString;
      let loginTime = getLoginData()?.loginTime;

      if (loginTime) {
        // Substract the offset between local time and cluster time
        // from the local login time to get cluster login time
        loginTime -= Date.now() - Date.clusterNow();
        dateString = DateTimeService.msecsToFormattedDate(loginTime);
      }

      return $filter('naFilter')(dateString);
    }

    /**
     * Returns true if this principal belongs to an organization but the
     * logged-in user is not of that organization.
     *
     * @method  isNotTenantAuthorized
     * @param   {Object}  principal  A User or Group to test.
     * @returns {Boolean} True if logged-in user does not belong to the same
     *                    organization as the principal.
     */
    function isNotTenantAuthorized(principal) {
      // Users have a tenantId, but groups have a list of tenantIds.
      return (principal.tenantId || principal.tenantIds) && !isTenantUser();
    }

    /**
     * Retrieve a single API Keys.
     *
     * @method  getApiKeys
     * @param   userSid  The user API Key belongs to.
     * @param  id  The ID of the API Key.
     * @return Promise of the API call.
     */
    function getApiKey(userSid, id) {
      return $http({
        method: 'get',
        url: API.public('users', userSid, 'apiKeys', id),
      }).then(function getApiKey(resp) {
        return resp.data;
      }, evalAJAX.errorMessage);
    }

    /**
     * Retrieves the list of API Keys.
     *
     * @method  getApiKeys
     * @return Promise of the API call
     */
    function getApiKeys() {
      return $http({
        method: 'get',
        url: API.public('usersApiKeys'),
      }).then(function getApiKeys(resp) {
        return resp.data;
      }, evalAJAX.errorMessage);
    }

    /**
     * Function to create an API Key.
     *
     * @method createApiKey
     * @param   userSid  The user API Key belongs to.
     * @param  id  The ID of the API Key.
     * @param  apiKey  The API Key to create.
     * @return Promise of the API call.
     */
    function createApiKey(userSid, id, apiKey) {
      return $http({
        method: 'post',
        url: API.public('users', userSid, 'apiKeys', id),
        data: apiKey,
      }).then(function successApiKey(apiKey) {
        cMessage.success({
          textKey: 'apiKeyCreated',
        });

        // When API Key is first created, go to API Key details with the key
        // visible. This is the only time when the key is show to the user.
        $state.go('modify-api-key', {
          id: apiKey.data.id,
          userSid: userSid,
          apiKeyToken: apiKey.data.key,
        });
      }, evalAJAX.errorMessage);
    }


    /**
     * Function to update an API Key.
     *
     * @method updateApiKey
     * @param   userSid  The user API Key belongs to.
     * @param  id  The ID of the API Key.
     * @param  apiKey  The API Key to update.
     * @return Promise of the API call.
     */
    function updateApiKey(userSid, id, apiKey) {
      return $http({
        method: 'put',
        url: API.public('users', userSid, 'apiKeys', id),
        data: apiKey,
      }).then(function successApiKey() {
        cMessage.success({
          textKey: 'apiKeyUpdated',
        });

        $state.go('access-management.api-keys');
      }, evalAJAX.errorMessage);
    }

    /**
     * Function to rotate an API Key.
     *
     * @method rotateApiKey
     * @param apiKey  The API key to rotate.
     * @return Promise of the API call.
     */
    function rotateApiKey(apiKey) {
      return $http({
        method: 'post',
        url: API.public(
          'users',
          apiKey.ownerUserSid,
          'apiKeys',
          apiKey.id,
          'rotate'
        ),
      }).then(function successApiKey(apiKeyResponse) {
        cMessage.success({
          textKey: 'apiKeyRotated',
        });
        $state.go('modify-api-key', {
          id: apiKeyResponse.data.id,
          userSid: apiKeyResponse.data.ownerUserSid,
          apiKeyToken: apiKeyResponse.data.key,
        });
      }, evalAJAX.errorMessage);
    }

    /**
     * Function to delete an API Key.
     *
     * @method deleteApiKey
     * @param apiKey  The API key to delete.
     * @return Promise of the API call
     */
    function deleteApiKey(apiKey) {
      return $http({
        method: 'delete',
        url: API.public(
          'users',
          apiKey.ownerUserSid,
          'apiKeys',
          apiKey.id
        ),
      }).then(function apiKeyDeleted() {
        cMessage.success({
          textKey: 'apiKeyDeleted',
        });
      }, evalAJAX.errorMessage);
    }

    /**
     * Sets Inactivity Timeout
     * If custom inactivity timeout provided by user then set to
     * provided time else set to default inactivity timeout
     *
     * @param inactivityTimeout custom inactivity timeout provided by user
     */
    function setInactivityTimeout(inactivityTimeout) {
      if (inactivityTimeout > 0) {
        allowedInactivityMsecs = inactivityTimeout;
      } else {
        allowedInactivityMsecs = defaultInactivityMsecs;
      }
    }

    return userService;
  }
})(angular);
